<template>
	<b-overlay
		variant="white"
		spinner-variant="primary"
		blur="0"
		opacity=".75"
		rounded="sm"
		:show="loading"
	>
		<b-card title="Manage your subscription">
			<b-card-text>
				<div class="row">
					<div class="col-12 col-md-6 col-lg-4">
						<div class="subscription-plan">
							<h2>Novice</h2>
							<h1>0 $</h1>
							<p>Free Forever and Ever!</p>
							<p>For makers who want to test some APIs.</p>
							<div class="text-left included-list">
								<p><img src="https://abracadalo.com/check.svg" alt="Included"> <span>50 Calls to Premium APIs</span></p>
								<p><img src="https://abracadalo.com/check.svg" alt="Included"> <span>1 App Setting</span></p>
								<p><img src="https://abracadalo.com/check.svg" alt="Included"> <span>Selection of String Functions</span></p>
								<p><img src="https://abracadalo.com/check.svg" alt="Included"> <span>Support</span></p>
							</div>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-4">
						<div class="subscription-plan">
							<h2>Apprentice</h2>
							<h1>{{ yearly ? yearlyPricePerMonth : monthlyPricePerMonth}} $</h1>
							<p v-if="yearly" >Per Month ({{ yearlyPricePerYear }} $ annually)</p>
							<p v-else>Per Month</p>

							<div class="custom-control custom-switch">
								<label for="billingSwitch" style="margin-right:6px">Monthly</label>
								<input type="checkbox" class="custom-control-input" id="billingSwitch" v-model="yearly">
								<label class="custom-control-label" for="billingSwitch">
									Yearly
									<b-badge variant="light-success">Save {{ savings }}%</b-badge>
								</label>
							</div>

							<p>For makers who want to develop an app.</p>
							<div class="text-left included-list">
								<p><img src="https://abracadalo.com/check.svg" alt="Included"> <span>Unlimited Calls</span></p>
								<p><img src="https://abracadalo.com/check.svg" alt="Included"> <span>1 App Setting</span></p>
								<p><img src="https://abracadalo.com/check.svg" alt="Included"> <span>Access to All APIs</span></p>
								<p><img src="https://abracadalo.com/check.svg" alt="Included"> <span>Priority Support</span></p>
							</div>
							<br>
							<b-button v-if="userPlan === 'Novice'" @click="checkout" variant="primary">Upgrade Now</b-button>
							<b-button v-if="userPlan === 'Apprentice'" @click="manage" variant="danger">Manage</b-button>
						</div>
					</div>

					<div class="col-12 col-md-6 col-lg-4">
						<div class="subscription-plan">
							<h2>Master Wizard</h2>
							<h1>{{ yearlyMaster ? yearlyPricePerMonthMaster : monthlyPricePerMonthMaster}} $</h1>
							<p v-if="yearlyMaster" >Per Month ({{ yearlyPricePerYearMaster }} $ annually)</p>
							<p v-else>Per Month</p>

							<div class="custom-control custom-switch">
								<label for="billingSwitchMaster" style="margin-right:6px">Monthly</label>
								<input type="checkbox" class="custom-control-input" id="billingSwitchMaster" v-model="yearlyMaster">
								<label class="custom-control-label" for="billingSwitchMaster">
									Yearly
									<b-badge variant="light-success">Save {{ savings }}%</b-badge>
								</label>
							</div>

							<p>For pros who need to go to the next level.</p>
							<div class="text-left included-list">
								<p><img src="https://abracadalo.com/check.svg" alt="Included"> <span>Unlimited Calls</span></p>
								<p><img src="https://abracadalo.com/check.svg" alt="Included"> <span>Unlimited Apps</span></p>
								<p><img src="https://abracadalo.com/check.svg" alt="Included"> <span>Access to All APIs</span></p>
								<p><img src="https://abracadalo.com/check.svg" alt="Included"> <span>Priority Support</span></p>
							</div>
							<br>
							<b-button v-if="userPlan === 'Novice'" @click="checkoutMaster" variant="primary">Upgrade Now</b-button>
							<b-button v-if="userPlan === 'Apprentice'" @click="manage" variant="primary">Upgrade Now</b-button>
							<b-button v-if="userPlan === 'Master Wizard'" @click="manage" variant="danger">Manage</b-button>
						</div>
					</div>
				</div>
			</b-card-text>
		</b-card>
	</b-overlay>
</template>

<script>
import { BCard, BCardText, BButton, BTable, BOverlay, BBadge, BAlert } from 'bootstrap-vue'
import { mapGetters } from "vuex";
import { createCheckoutSession, sendToCustomerPortal } from "../stripe/functions";
import getUserPlan from "../stripe/getUserPlan";

export default {
	components: {
		BCard,
		BCardText,
		BButton,
		BTable,
		BOverlay,
		BBadge,
		BAlert
	},
	data: () => ({
		subscription: null,
		userPlan: 'Novice',
		loading: true,
		pricing: {
			monthly: 10.66,
			annually: 96
		},
		pricingMaster: {
			monthly: 24,
			annually: 216
		},
		yearly: true,
		yearlyMaster: true
	}),
	computed: {
		...mapGetters({
			user: "auth/user"
		}),
		yearlyPricePerMonth() {
			return (this.pricing.annually/12).toFixed(2)
		},
		yearlyPricePerYear() {
			return this.pricing.annually.toFixed(2)
		},
		monthlyPricePerMonth() {
			return this.pricing.monthly.toFixed(2)
		},
		monthlyPricePerYear() {
			return (this.pricing.monthly*12).toFixed(2)
		},
		yearlyPricePerMonthMaster() {
			return (this.pricingMaster.annually/12).toFixed(2)
		},
		yearlyPricePerYearMaster() {
			return this.pricingMaster.annually.toFixed(2)
		},
		monthlyPricePerMonthMaster() {
			return this.pricingMaster.monthly.toFixed(2)
		},
		monthlyPricePerYearMaster() {
			return (this.pricingMaster.monthly*12).toFixed(2)
		},
		savings() {
			return Math.round((1 - this.pricing.annually/12 / this.pricing.monthly) * -100);
		}
	},
	created() {
		getUserPlan().then(res => {
			this.loading = false;
			this.userPlan = res;
		})
	},
	methods: {
		checkout() {
			this.loading = true;
			createCheckoutSession(this.user.data.uid, this.yearly, 'apprentice');
		},
		checkoutMaster() {
			this.loading = true;
			createCheckoutSession(this.user.data.uid, this.yearlyMaster, 'master');
		},
		manage() {
			this.loading = true;
			sendToCustomerPortal()
		}
	}
}
</script>

<style lang="scss" scoped>
.subscription-plan {
	min-height: 420px;
	display: inline-block;
	margin-bottom: 2rem;
	border-radius: 20px;
	padding: 34px;
	border: 1px solid lightgray;
	text-align: center;

	h1 {
		font-weight: bolder;
		font-size: 38px;
	}

	h2 {
		font-size: 20px;
	}

	.included-list {
		margin-top: 32px;

		p > img {
			margin-right: 8px;
		}
	}
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  .b-overlay-wrap ::v-deep .b-overlay {
    // border: 10px solid red;
    .bg-white {
      background-color: $theme-dark-body-bg !important;
    }
  }
}
</style>
