import initializeStripe from "./initializeStripe";
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';

function getPrice(yearly, plan) {
  if (plan === 'master') {
    return yearly ? "price_1KkuzfLqUTCzLDOT6iWLM7av" : "price_1KkwSOLqUTCzLDOTRjs6Y4qB"
  } else {
    return yearly ? "price_1KkwWZLqUTCzLDOT8s5DCXbd" : "price_1KkwWqLqUTCzLDOTwXxPvXlL"
  }
}

export async function createCheckoutSession(uid, yearly, plan) {
  const firestore = firebase.firestore();
  const checkoutSessionRef = await firestore
    .collection("users")
    .doc(uid)
    .collection("checkout_sessions")
    .add({
      price: getPrice(yearly, plan),
      success_url: window.location.href,
      cancel_url: window.location.href,
      allow_promotion_codes: true
    });

  checkoutSessionRef.onSnapshot(async (snap) => {
    console.log(snap)
    const { sessionId } = snap.data();
    if (sessionId) {
      const stripe = await initializeStripe();
      stripe.redirectToCheckout({ sessionId });
    }
  });
}

export async function sendToCustomerPortal() {
	const { data } = await firebase.functions().httpsCallable("ext-firestore-stripe-payments-createPortalLink")({
		returnUrl: window.location.origin
	});
	window.location.assign(data.url);
}
