import { loadStripe } from "@stripe/stripe-js";

let stripePromise;

const initializeStripe = async () => {
  if (!stripePromise) {
    stripePromise = await loadStripe("pk_live_51Jn8OULqUTCzLDOT2w52GWtqrapeTORrpfujlPjUjwVGUMFH6vxil3ynYc90W2NkP4Op9C4FoDpUI5LnrUXAl4oj00E6SMp09P");
  }
  return stripePromise;
};

export default initializeStripe;
